import React from "react";
import styled from "styled-components";

function TimelineItem({
  label,
  link,
  department,
  topics,
  location,
  date,
  type,
}) {
  return (
    <TimelineItemContainer>
      <TimelineLocationDateContainer>
        <TimelineDate>{date}</TimelineDate>
        <TimelineLocation>{location}</TimelineLocation>
      </TimelineLocationDateContainer>
      <TimelineDescription type={type}>
        <TimelineBadge type={type} />
        <h2>
          {link ? (
            <a href={link} target="_blank" rel="noopener noreferrer">
              {label}
            </a>
          ) : (
            <span>{label}</span>
          )}
        </h2>
        
        {department && <span>{department}</span>}

        <ul>
          {topics.map((topic, index) => {
            return <li key={index}>
              {topic.link ? (
                <a
                  href={topic.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  class="item-details"
                >
                  {topic.name}
                </a>
              ) : (
                <span class="item-details">{topic.name}</span>
              )}
              <ul>
                {topic.activities.map((activity, index) => {
                  return <li key={index}>{activity}</li>;
                })}
              </ul>
            </li>;
          })}
        </ul>
      </TimelineDescription>
    </TimelineItemContainer>
  );
}

export default TimelineItem;

const TimelineItemContainer = styled.div`
  display: flex;
`;

const TimelineLocationDateContainer = styled.div`
  margin-top: 10px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  @media (max-width: 640px) {
    width: 90px;
  }

  @media (min-width: 641px) {
    width: 175px;
  }
`;

const TimelineBadge = styled.div`
  position: absolute;
  background-color: white;
  left: -11px;
  top: 20px;
  z-index: 1;
  width: 16px;
  height: 16px;
  border: 2px solid
    ${(props) => (props.type === "education" ? "mediumseagreen" : "dodgerblue")};
  border-radius: 16px;
`;

const TimelineDescription = styled.div`
  position: relative;
  border-left: 2px solid
    ${(props) => (props.type === "education" ? "mediumseagreen" : "dodgerblue")};
  margin-left: 25px;
  padding: 15px 25px;
  display: flex;
  flex-direction: column;
`;

const TimelineDate = styled.span`
  font-size: 15px;
  color: indianred;
  text-align: right;
`;

const TimelineLocation = styled.span`
  margin-top: 5px;
  font-family: "Roboto Italic";
  font-size: 12px;
  color: darkslateblue;
`;

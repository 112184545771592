import React from "react";
import "./App.css";
import PhoneIcon from "./assets/images/phone.svg";
import EmailIcon from "./assets/images/mail.svg";
import GitHubIcon from "./assets/images/github.svg";
import TimelineItem from "./components/TimelineItem";
import styled from "styled-components";

function App() {
  return (
    <AppContainer>
      <div class="name-container">
        <span class="firstname">Mathis</span>&nbsp;
        <span class="lastname">Gilliot</span>
      </div>
      <div class="info-container">
        <span class="position">Software Engineer</span>
        <span class="address">Paris, France</span>
        <SubinfoContainer>
          <SubinfoLink href="tel:+33659085699" class="subinfo">
            <img src={PhoneIcon} alt="Phone" height="20px" />
            <span class="subinfo-label">
              +33&nbsp;6&nbsp;59&nbsp;08&nbsp;56&nbsp;99
            </span>
          </SubinfoLink>
          <Separator></Separator>
          <SubinfoLink href="mailto:mathisgilliot@gmail.com" class="subinfo">
            <img src={EmailIcon} alt="Mail" height="20px" />
            <span class="subinfo-label">mathisgilliot@gmail.com</span>
          </SubinfoLink>
          <Separator></Separator>
          <SubinfoLink href="https://github.com/mathisGilliot/" class="subinfo">
            <img src={GitHubIcon} alt="GitHub" height="20px" />
            <span class="subinfo-label">GitHub</span>
          </SubinfoLink>
        </SubinfoContainer>
      </div>

      <div class="title-container">
        <h1>Experience</h1>
        <div class="bottom-line"></div>
      </div>

      <TimeLine>
        <TimelineItem
          label="Microsoft"
          department="Microsoft Engineering Center"
          topics={[
            {name: "Power Automate", link: "https://powerautomate.microsoft.com/en-us/robotic-process-automation/", activities: [
              "Power Automate is a low-code no-code platform for business developers. ",
              "Robotic Process Automation (RPA) allows customers to quickly automate repetitive steps from their processes. Customers can use the Power Automate Desktop application to record and build Desktop flows on their computer. Desktop flows can be analyzed thanks to Process Advisor capabilities. Then cloud flows can be built to automate most of the steps and schedule execution.",
              "AI Builder can provide basic machine learning integrations as part of the cloud flows for example by extracting key values from invoices."
            ]},
            {name: "RPA", link: "https://powerautomate.microsoft.com/en-us/robotic-process-automation/", activities: [
              "Work on queuing system: dispatch runs load to available machines as fast as possible;",
              "Add UX components necessary to execute flows on auto-provisioned Azure virtual machines."
            ]},
            {name: "Process Advisor", link: "https://powerautomate.microsoft.com/en-us/process-advisor/", activities: [
              "Setup the new backend service in charge of the recordings ingestion and the process analysis.",
              "Provide connectors recommendations based on the process analysis to create cloud flows."
            ]},
            {name: "AI Builder", link: "https://powerapps.microsoft.com/en-us/ai-builder/", activities: [
              "Work on backend orchestration of AI service: model creation, training, predictions.",
              "Added pre-trained sentiment analysis scenario.",
              "Implemented a smooth drag and drop mapping experience inside AI Builder graph viewer.",
            ]}]}
          location="Paris, France"
          date="Sep. 2018 - Now"
        />

        <TimelineItem
          label="Internship @ Microsoft"
          department="Microsoft Engineering Center"
          topics={[{name: "Microsoft Edge", activities: ["Books/PDF reader: Annotation panel"]}]}
          location="Paris, France"
          date="Feb. 2018 - Jul. 2018"
        />

        <TimelineItem
          label="Lund University"
          topics={[{name: "Exchange in LTH", activities: [
            "Advanced Algorithms",
            "Advanced Computer Security",
            "Constraint Programming",
            "High Performance Computer Graphics",
          ]}]}
          location="Lund, Sweden"
          date="Aug. 2017 - Jan. 2018"
          type="education"
        />

        <TimelineItem
          label="Internship @ Monext"
          link="https://www.monext.fr/en"
          department="Payment Service Provider"
          topics={[{name: "Authorization service", activities: [
            "Monitoring of rules: Developing scripts to check if the rules are enabled after each update.",
            "Monitoring of volumes: Created a Java tool to check, for each defined key, if the volume is similar to the volume before the update.",
          ]}]}
          location="Aix-en-Provence, France"
          date="May 2017 - Aug. 2017"
        />

        <TimelineItem
          label="Ensimag Grenoble INP"
          topics={[{name: "Information System Engineering", activities: [
            "Java project: Build a compiler for a subset of Java language.",
            "C project: Implement a graphical user interface library from SDL primitives.",
          ]}]}
          location="Grenoble, France"
          date="2015 - 2018"
          type="education"
        />

        <TimelineItem
          label="Lycée Carnot"
          topics={[{name: "Preparatory classes", activities: [
            "3-year preparation oriented mathematics and physics to take the national exams to enter into top French engineering schools.",
          ]}]}
          location="Dijon, France"
          date="2012 - 2015"
          type="education"
        />
      </TimeLine>

      <div class="title-container">
        <h1>Skills</h1>
        <div class="bottom-line"></div>
      </div>

      <div class="item-container">
        <div class="item-description">
          <h2>Languages</h2>
          <ul>
            <li>French: Native</li>
            <li>English: TOEFL iBT Total: 92 (Nov. 4, 2016)</li>
            <li>Spanish: Intermediate</li>
          </ul>
        </div>
      </div>

      <div class="item-container">
        <div class="item-description">
          <h2>Computer skills</h2>
          <ul>
            <li>C#, TypeScript, Java, Python, C/C++, Git</li>
          </ul>
        </div>
      </div>

      <div class="title-container">
        <h1>Hobbies</h1>
        <div class="bottom-line"></div>
      </div>

      <div class="item-container">
        <div class="item-description">
          <h2>Running</h2>
          <ul>
            <li>Half-Marathon</li>
          </ul>
        </div>
      </div>
    </AppContainer>
  );
}

export default App;

const AppContainer = styled.div`
  font-family: "Roboto Regular";
  @media (max-width: 640px) {
    padding: 15px;
  }

  @media (min-width: 641px) {
    padding: 25px 100px;
  }
`;

const SubinfoContainer = styled.div`
  display: flex;
  font-size: 14px;
  margin-top: 5px;
  @media (max-width: 640px) {
    flex-direction: column;
  }

  @media (min-width: 641px) {
    flex-direction: row;
  }
`;

const Separator = styled.div`
  margin-left: 7px;
  border-left: 1px solid gray;
  padding-left: 7px;

  @media (max-width: 640px) {
    display: none;
  }
`;

const SubinfoLink = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: gray;

  @media (max-width: 640px) {
    margin-top: 5px;
  }

  :hover {
    text-decoration: underline;
  }
`;

const TimeLine = styled.div`
  margin-top: 15px;
`;
